import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export default class Navbar extends Component {
  render() {
    return (
      <nav id="navbar-menu" className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <Link to="./" className="navbar-brand">
            <img
              // src="https://itetlax.org.mx/assets/img/logoite.png"
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/participacionCiudadana.png"
              alt="Micrositio Participación Ciudadana"
              className="img-fluid Navbar__logoITE"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="./" className="nav-link">
                  Inicio
                </Link>
              </li>
              <li className="nav-item dropdown">
          <span className="nav-link dropdown-toggle">
            Mecanismos
          </span>
          <ul className="dropdown-menu">
            {/* <li className="nav-item">
              <Link to="./IniciativaPopular" className="nav-link">
                Iniciativa Popular
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="./ConsultaPopular" className="nav-link">
                Consulta Popular
              </Link>
            </li>
            <li className="nav-item">
              <Link to="./Plebiscito" className="nav-link">
                Plebiscito
              </Link>
            </li>
            <li className="nav-item">
              <Link to="./Referendum" className="nav-link">
                Referéndum
              </Link>
            </li>
            <li className="nav-item">
              <Link to="./VozCiudadana" className="nav-link">
                Voz Ciudadana en el Cabildo
              </Link>
            </li>
          </ul>
        </li>
 
              <li className="nav-item">
                <Link to="./Infografias" className="nav-link">
                  Infografías
                </Link>
              </li>

              <li className="nav-item">
                <a
                  href="https://participacion-ciudadana.itetlax.org.mx/assets/pdf/ESTUDIO DE PARTICIPACIÓN CIUDADANA 2020-2021.pdf" // Cambia esta URL por la que desees
                  className="nav-link"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Estudios
                </a>
              </li>


              <li className="nav-item">
                <Link to="./Convenios" className="nav-link disabled">
                  Convenios
                </Link>
              </li>
              <li className="nav-item">
                <Link to="./Eventos" className="nav-link disabled">
                  Eventos
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
