export const dataInfoografias = [
  {
    id: 1,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/1.jpeg",
    alt: "Mecanismos de Participacion Ciudadana",
    grupo: 1,
  },
  {
    id: 2,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/2.jpeg",
    alt: "Iniciativa Popular",
    grupo: 1,
  },
  {
    id: 3,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/3.png",
    alt: "Consulta Popular",
    grupo: 1,
  },
  {
    id: 4,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/4.png",
    alt: "Plebiscito",
    grupo: 1,
  },
  {
    id: 5,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/5.jpg",
    alt: "Referéndum",
    grupo: 1,
  },
  {
    id: 6,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/6.jpg",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 1,
  },

  {
    id: 7,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/7.png",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 1,
  },


  {
    id: 8,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/8.png",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 1,
  },
  
  {
    id: 9,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/9.png",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 1,
  },
  
  {
    id: 10,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/InfografiasTemp/10.png",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 1,
  },





  {
    id: 11,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/1.jpg",
    alt: "Mecanismos de Participacion Ciudadana",
    grupo: 2,
  },
  {
    id: 12,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/2.jpg",
    alt: "Iniciativa Popular",
    grupo: 2,
  },
  {
    id: 13,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/3.jpg",
    alt: "Consulta Popular",
    grupo: 2,
  },
  {
    id: 14,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/4.jpg",
    alt: "Plebiscito",
    grupo: 2,
  },
  {
    id: 15,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/5.jpg",
    alt: "Referéndum",
    grupo: 2,
  },
  {
    id: 16,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/6.jpg",
    alt: "Voz Ciudadana en el Cabildo",
    grupo: 2,
  },
  
];
