import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <>
        <img
          className="img-fluid w-100"
          src="https://participacion-ciudadana.itetlax.org.mx/assets/img/header3.jpg"
          alt="Mecanismos de Participación Ciudadana"
        />
        <h1 className="text-center mb-4 mt-3">MARCO LEGAL</h1>
        <div className="row">
          <div className="col-md-6">
            <ul>
              <li>Constitución Política de los Estados Unidos Mexicanos</li>
              <p className="ms-5">
                <a
                  href="https://www.diputados.gob.mx/LeyesBiblio/ref/cpeum.htm"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://www.diputados.gob.mx/LeyesBiblio/ref/cpeum.htm
                </a>
              </p>
              <li>
                Constitución Política del Estado Libre y Soberano de Tlaxcala
              </li>
              <p className="ms-5">
                <a
                  href="https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/5_constitucion_pol.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/5_constitucion_pol.pdf
                </a>
              </p>
              <li>
                Ley de Instituciones y Procedimientos Electorales para el Estado
                de Tlaxcala
              </li>
              <p className="ms-5">
                <a
                  href="https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/43_Ley_de_instituc.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/43_Ley_de_instituc.pdf
                </a>
              </p>
              <li>Ley de Consulta Ciudadana para el Estado de Tlaxcala</li>
              <p className="ms-5">
                <a
                  href="https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/23_Ley_de_consulta.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://congresodetlaxcala.gob.mx/archivo/leyes2020/pdf/23_Ley_de_consulta.pdf
                </a>
              </p>
            </ul>
          </div>
          <div className="col-md-6">
            <ul>
              <li>
                Reglamento de Comisiones del Instituto Tlaxcalteca de Elecciones
              </li>
              <p className="ms-5">
                <a
                  href="https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/9.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/9.pdf
                </a>
              </p>
              {/* <li>
                Acuerdo CG 28/2016 que regula los procedimientos del IET
                relativos a la Ley de Consulta Ciudadana para el Estado de
                Tlaxcala
              </li>
              <p className="ms-5">
                <a
                  href="https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2019/6.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2019/6.pdf
                </a>
              </p> */}
              <li>
                Acuerdo ITE-CG 69/2022, mediante el cual se designa a la Titular
                del Área Técnica de Consulta Ciudadana
              </li>
              <p className="ms-5">
                <a
                  href="https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/69.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/69.pdf
                </a>
              </p>
              <li>
                Acuerdo ITE-CG 298/2024, por el que se integran las comisiones y comités, para el cumplimiento de los fines y atribuciones del instituto.
              </li>
              <p className="ms-5">
                <a
                  href="https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/298.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-break"
                >
                  https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/298.pdf
                </a>
              </p>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
