import React, { Component } from "react";
import ModalIMG from "../layout/ModalIMG";
import { dataInfoografias } from "../data/dataInfografias";

export default class Infografias extends Component {
  render() {
    // Agrupa la data por el campo "grupo"
    const groupedData = dataInfoografias.reduce((acc, item) => {
      if (!acc[item.grupo]) {
        acc[item.grupo] = [];
      }
      acc[item.grupo].push(item);
      return acc;
    }, {});

    return (
      <>
        <h1 className="text-center mb-4 mt-3">INFOGRAFÍAS</h1>
        {/* Agregamos el CSS directamente aquí */}
        <style>
          {`
            .Galeria__responsive {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 16px; 
              margin-bottom: 120px;  
            }
            .Galeria__responsive-item {
              width: 100%; 
            }
          `}
        </style>

        {/* Mapea cada grupo y renderiza las imágenes */}
        {Object.keys(groupedData).map((grupo) => (
          <div key={grupo} className="Galeria__responsive">
            {groupedData[grupo].map((img) => (
              <div key={img.id} className="Galeria__responsive-item">
                <a
                  href="/#"
                  rel="noreferrer"
                  data-bs-toggle="modal"
                  data-bs-target={"#Modal" + img.id}
                >
                  <img
                    src={img.imgURL}
                    alt={img.alt}
                    className="img-fluid rounded"
                  />
                </a>
                <ModalIMG
                  idModal={"Modal" + img.id}
                  altIMG={img.alt}
                  linkIMG={img.imgURL}
                />
              </div>
            ))}
          </div>
        ))}
      </>
    );
  }
}